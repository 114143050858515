@import "../theme.scss";

.biscuit-banner-background {
    position: fixed;
    z-index: 9997;
    right: 0;
    bottom: -200px;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.biscuit-banner-container {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 9998;
    vertical-align: middle;
    white-space: nowrap;
    max-height: 100%;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    font-size: 14px;
    overflow-y: scroll;
}

.biscuit-banner-container:after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.biscuit-banner-inner-container {
    width: 50%;
    height: auto;
    max-width: none;
    border-radius: 15px;
    display: inline-block;
    z-index: 9999;
    background-color: #fff;
    text-align: left;
    white-space: normal;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
    position: relative;
    border: 1px solid #fff;
    vertical-align: middle;
    padding: 30px;
}

.biscuit-banner-headline {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.biscuit-cta-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.biscuit-cta-container > button {
    display: block;
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 53px;
    background: $secondary-main;
    border-radius: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
    border: none;
}

@media only screen and (max-width: 950px) {
    .biscuit-banner-inner-container {
        width: 80%;
        height: auto;
        max-width: none;
        border-radius: 15px;
        display: inline-block;
        z-index: 9999;
        background-color: #fff;
        text-align: left;
        white-space: normal;
        box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
        position: relative;
        border: 1px solid #fff;
        vertical-align: middle;
        padding: 30px;
    }
    
    .biscuit-cta-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
    }

    .biscuit-cta-container > button {
        display: block;
        display: flex;
        cursor: pointer;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 53px;
        background: $secondary-light;
        border-radius: 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        flex: none;
        order: 1;
        flex-grow: 0;
        border: none;
    }
}

@media only screen and (max-width: 450px) {
    .biscuit-banner-inner-container {
        width: 80%;
        height: auto;
        max-width: none;
        border-radius: 15px;
        display: inline-block;
        z-index: 9999;
        background-color: #fff;
        text-align: left;
        white-space: normal;
        box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
        position: relative;
        border: 1px solid #fff;
        vertical-align: middle;
        padding: 20px;
    }

    .biscuit-cta-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
    }

    .biscuit-cta-container > button {
        display: block;
        display: flex;
        cursor: pointer;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 25px;
        background: $secondary-light;
        border-radius: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        flex: none;
        order: 1;
        flex-grow: 0;
        border: none;
    }

    .biscuit-banner-headline {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .biscuit-banner-texts {
        font-size: 12px;
    }
}