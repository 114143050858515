@import "../theme.scss";

.bg {
    display: block;
    position: absolute;
    z-index: -10;
    width: 100vw;
    min-height: 100vh;
}

.hide { 
    overflow: hidden;
}

.circle, .squircle {
    width: 12.5em;
    height: 12.5em;
    position: absolute;
    border-top: 0.1em solid rgba(255,255,255,0.4);
    z-index: 0;
}

.circle {
    border-radius: 50%;
    left: calc(10% - 6.25em);
    top: calc(50% - 12.5em);
    transform-origin: 50% 12.5em;
}

.squircle {
    border-radius: 25%;
}

.small {
    width: 4em;
    height: 4em;
    left: calc(10% - 2em);
    top: calc(50% - 15em);
    transform-origin: 50% 15em;
    box-shadow: 0 0.25em 0.5em rgba(0,0,0,0.2);
}

.medium {
    width: 8em;
    height: 8em;
    left: calc(10% - 2em);
    top: calc(50% - 15em);
    transform-origin: 50% 15em;
    box-shadow: 0 0.25em 0.5em rgba(0,0,0,0.2);
}

.large {
    width: 16em;
    height: 16em;
    left: calc(10% - 2em);
    top: calc(50% - 15em);
    transform-origin: 50% 15em;
    box-shadow: 0 0.25em 0.5em rgba(0,0,0,0.2);
}


.xlarge {
    width: 20em;
    height: 20em;
    left: calc(10% - 2em);
    top: calc(50% - 15em);
    transform-origin: 50% 15em;
    box-shadow: 0 0.25em 0.5em rgba(0,0,0,0.2);
}


.orange {
    &_light {
        background-color: $secondary-light;
        &_outline {
            border: 5px solid $secondary-light;
            background-color: $white;
        }
    }
    &_main {
        background-color: $secondary-main;
        &_outline {
            border: 5px solid $secondary-main;
            background-color: $white;
        }
    }
    &_dark {
        background-color: $secondary-dark;
        &_outline {
            border: 5px solid $secondary-dark;
            background-color: $white;
        }
    }
}

.one {
    opacity: 80%;
    transform: rotateZ(225deg);
}

.two {
    transform: rotateZ(270deg);
    top: calc(10% - 5em);
    left: calc(-2.5em);
}

.three {
    opacity: 80%;
    transform: rotateZ(150deg);
}

.four {
    opacity: 80%;
    transform: rotateZ(90deg);
    top: calc(60% - 15em);
    left: calc(60% - 5em)
}

.five {
    opacity: 80%;
    top: calc(100% - 15em);
    transform: rotateZ(30deg);
    transform-origin: 20%;
}

.six {
    opacity: 60%;
    top: calc(10% - 15em);
}

.seven {
    opacity: 80%;
    transform: rotateZ(90deg);
}

.eight {
    opacity: 80%;
    left: calc(90% - 10em);
}

.nine {
    opacity: 80%;
    left: 50%;
    top: 90%;
}

.ten {
    left: calc(100% - 10em);
    top: 10%;
    transform: rotateZ(30deg)
}

.eleven {
    left: calc(90% - 15em);
    top: 10%;
}

.twelve {
    opacity: 60%;
    left: calc(100% - 15em);
    top: calc(100% - 15em);
}

.thirteen {
    opacity: 80%;
    left: 80% ;
    top: 80%;
}

.fourteen {
    left: 30%;
    top: calc(80% - 15em);
    z-index: 1
}

.fifteen {
    left: 30%;
    top: calc(80% - 15em);
}

.sixteen {
    left: 80%;
    top: calc(40% - 15em);
    transform: rotateZ(30deg)
}
.seventeen {
    left: 85%;
    top: 65%
}

.eighteen {
    left: 20%;
    top: 20%
}