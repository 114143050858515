// themes 
// primary - grey 
$primary-light: #69696a;
$primary-main: #28282a;
$primary-dark: #1e1e1f;

// secondary - orange
$secondary-light: #ffb8a9;
$secondary-main: #FF5733;
$secondary-dark: #ff370c;

// warning 
$warning-main: #ffc071;
$warning-dark: #ffb25e;

// error 
$error-light: #ffebee;
$error-main: #f44336;
$error-dark: #d32f2f;

// success 
$success-light: #e8f5e9;
$success-main: #4caf50;
$success-dark: #4caf50;

// white
$white: #fff;

$container-height: 100vh;

// spacing 
$spacing: 2px, 4px, 8px, 16px, 32px;